import React, { useEffect, useState, useRef } from "react"
import { default as ReactSelect } from "react-select"
import _ from "lodash"
import helpers from "helpers"

function Select(props) {
	//? We store value in state because formik's resetForm was keeping this selects display.
	const [value, setValue] = useState(null)

	useEffect(() => {
		if (props.value !== null) {
			let f = _.find(props.options, (v) => v.value === props.value)
			if (f) {
				setValue(f)
			}
		} else setValue(null)
	}, [props.value, props.options])

	return (
		<ReactSelect
			{...props}
			className={`Select ${props.className}`}
			isDisabled={props.disabled}
			key={props.name}
			value={helpers.selectValue(props.options, props.value, props.allowNull)}
			onChange={(v, triggeredAction) => {
				if (props.isMulti) {
					if (v === null) {
						v = []
					}
					let f = []
					_.forEach(v, (r) => {
						f.push(r.value)
					})
					if (f.length === 0) {
						// validation fails without this because blank arrays are removed from formik for some reason.
						f = ""
					}
					props.onChange(props.name, f)
				} else {
					props.onChange(props.name, v?.value)
				}
				if (triggeredAction.action === "clear" && props.clearLayerRef) {
					props.clearLayerRef()
				}
			}}
			isMulti={props.isMulti ? true : false}
			isClearable={props.clearable}
			onBlur={() => {
				if (props.onBlur) props.onBlur(props.name, true)
			}}
		/>
	)
}

export default Select
